<template>
  <div class="hd_module">
    <div class="list_tit">
      <h2>已添加硬件列表</h2>
      <div class="input_group">
        <el-select v-model="f_obj.status" clearable placeholder="请选择状态" @change="gethdInfo">
          <el-option
            v-for="item in f_status"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="f_obj.c_id" clearable placeholder="请选择客户" @change="gethdInfo" style="margin-left:16px;">
          <el-option
            v-for="item in f_ent"
            :key="item.c_id"
            :label="item.customer_name"
            :value="item.c_id">
          </el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-connection" @click="addHdvisible = true" style="margin-left:16px;">添加硬件</el-button>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="list_cnt">
      <el-table
        :data="hdList"
        stripe
        style="width: 100%">
        <el-table-column
          prop="enterprise"
          label="绑定企业">
        </el-table-column>
        <el-table-column
          prop="hardware_type">
        </el-table-column>
        <el-table-column
          prop="hardware_number"
          label="硬件编号">
        </el-table-column>
        <el-table-column
          prop="system_license"
          label="系统编号" width="460">
          <template slot-scope="scope">
            <div v-for="i in scope.row.system_license" :key="i">
              {{i}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="real_name" label="状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status==-1?'info':scope.row.status==0?'danger':'success'">{{scope.row.status|turn}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="created_time"
          label="绑定时间" width="180">
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="60">
          <template slot-scope="scope" >
            <el-popover
              :ref="`popover-${scope.$index}`"
              placement="left"
              v-model="visible">
              <p style="margin: 12px 0px;">确定要删除该硬件吗？</p>
              <div style="text-align: right;">
                <el-button size="mini" type="text" @click="popoverClose(scope)">取消</el-button>
                <el-button type="primary" size="mini" @click="yesDel(scope)">确定</el-button>
              </div>
              <el-button slot="reference" type="text">删除</el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog 
      title="添加硬件" 
      :visible.sync="addHdvisible"
      :close-on-click-modal="false"
      width='440px'>
      <el-form :model="addForm" :rules="rules" ref="addForm" label-width="85px">
        <el-form-item label="绑定企业:" prop="enterprise">
          <el-input v-model="addForm.enterprise" placeholder="必填"></el-input>
        </el-form-item>
        <el-form-item label="硬件类型:" prop="hardware_type">  
          <el-select v-model="addForm.hardware_type" placeholder="请选择硬件类型">
            <el-option
              v-for="item in hdType"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="硬件编号:" prop="hardware_number">
          <el-input v-model="addForm.hardware_number" placeholder="必填"></el-input>
        </el-form-item>
        <el-form-item label="巡鉴编号:" prop="xunjian_license" v-if="addForm.hardware_type=='巡鉴'||addForm.hardware_type=='巡御'">
          <el-input v-model="addForm.xunjian_license" placeholder="必填"></el-input>
        </el-form-item>
        <el-form-item label="巡镜编号:" prop="xunjing_license" v-if="addForm.hardware_type=='巡镜'||addForm.hardware_type=='巡御'">
          <el-input v-model="addForm.xunjing_license" placeholder="必填"></el-input>
        </el-form-item>
        <el-form-item label="巡塔编号:" prop="xunta_license" v-if="addForm.hardware_type=='巡塔'||addForm.hardware_type=='巡御'">
          <el-input v-model="addForm.xunta_license" placeholder="必填"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addHdvisible = false">取 消</el-button>
        <el-button type="primary" @click="addHd">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data(){
    return {
      hdList:[],
      addHdvisible:false,
      addForm:{},
      hdType:['巡鉴','巡镜','巡塔','巡御'],
      f_status:[{value:1,label:'在线'},{value:0,label:'离线'}],
      f_ent:[],
      f_obj:{
        status:null,
        c_id:''
      },
      rules:{//表单验证
        'enterprise':[
          {required: true,message: '请输入绑定企业',trigger: 'blur'}
        ],
        'hardware_type':[
          {required: true,message: '请输入硬件类型',trigger: 'change'}
        ],
        'hardware_number':[
          {required: true,message: '请输入硬件编号',trigger: 'blur'}
        ],
      }
    }
  },
  filters:{
    turn(v){
      if(v){
        return '在线'
      }else{
        return '离线'
      }
    }
  },
  methods:{
    gethdInfo(){//获取硬件信息列表
      this.$http({
        method:'post',
        url: '/hardware/read_device',
        data:this.f_obj
      }).then(res => {
        console.log(res)
        if(res.data.code==200){
          this.hdList = res.data.result;
        }
      }).catch(error =>{
        console.log(error)
      });
    },
    getEntlist(){//获取企业列表
      this.$http({
        method:'get',
        url: '/list_ent',
      }).then(res => {
        // console.log(res)
        if(res.data.code==0){
          this.f_ent = res.data.data;
        }
      }).catch(error =>{
        console.log(error)
      });
    },
    addHd(){//添加硬件
      this.$refs['addForm'].validate((valid) => {
        // console.log(this.addForm)
        if (valid) {
          this.$http({
            method:'post',
            url: '/hardware/add_device',
            data:this.addForm
          }).then(res => {
            // console.log(res)
            if(res.data.code==200){
              this.gethdInfo();
              this.$message.success(res.data.msg)
              this.addHdvisible = false;
            }else{
              this.$message.error(res.data.msg)
            }
          }).catch(error =>{
            console.log(error)
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    popoverClose(scope){//关闭删除确认框
      this.$refs[`popover-${scope.$index}`].doClose()
    },
    yesDel(scope){//确认删除硬件
      this.$http({
        method:'post',
        url: '/hardware/del_device',
        data:{'hardware_number':scope.row.hardware_number,'system_license':scope.row.system_license}
      }).then(res => {
        console.log(res)
        if(res.data.code==200){
          this.gethdInfo();
          this.$message.success(res.data.msg);
          this.popoverClose(scope)
        }else{
          this.$message.success(res.data.msg);
        }
      }).catch(error =>{
        console.log(error)
      });
    },
  },
  created(){
    this.gethdInfo();
    // this.getEntlist();
    // this.c_name = JSON.parse(localStorage.getItem("cInfo")).user_name;
  },
}
</script>

<style lang="scss" scoped>
  .hd_module ::v-deep{
    // display: flex;
    height: 100%;
    padding: 40px;
    background-color: #fff;
    // border-radius: 28px;
    box-shadow: 0px 0px 15px rgba(174, 187, 216, 0.5);
    .list_cnt{
      .el-table{
        height: calc(100vh - 56px - 80px - 30px - 49px );
        .el-table__header{
          text-align: left;
          .cell{
            font-weight: 900;
            font-size: 14px;
          }
        }
        .el-table__body-wrapper{
          height: calc(100% - 40px);
          overflow-y: auto;
          .el-table__row{
            .cell{
              padding: 8px 10px;
            }
          }
          .el-table__row--striped{
            background-color: $light-base;
            border-radius: 12px;
          }
        }
      }
    }
    .list_tit{
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2{
        font-size: 18px;
        font-weight: 900;
      }
      .input_group{

      }
      // margin-bottom: 28px;
    }
  }
</style>
